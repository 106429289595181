import {
  AutoGraph,
  DashboardOutlined,
  FileCopy,
  FolderCopy,
  HelpCenter,
  Home,
  Language,
  MapsHomeWork,
  People,
  Repeat,
  Settings,
} from '@mui/icons-material'
import { ROUTES } from 'constants/routes'

export const getActiveIconType = (activeLink: string) => {
  const iconsMap = {
    [ROUTES.ORGANIZATION]: Language,
    [ROUTES.DASHBOARD]: DashboardOutlined,
    [ROUTES.LEGAL_ENTITY_MANAGEMENT]: MapsHomeWork,
    [ROUTES.TRANSACTION_MANAGEMENT]: Repeat,
    [ROUTES.DOCUMENT_MANAGEMENT]: FolderCopy,
    [ROUTES.BENCHMARK_MANAGEMENT]: FileCopy,
    [ROUTES.ACTIVITY_LOG]: AutoGraph,
    [ROUTES.USER_ACCESS_MANAGEMENT]: People,
    [ROUTES.HELP_CENTER]: HelpCenter,
    [ROUTES.SETTINGS]: Settings,
    [ROUTES.HOME]: Home,
  }

  let icon = DashboardOutlined

  Object.keys(iconsMap).forEach(route => {
    if (activeLink.startsWith(route)) {
      icon = iconsMap[route]
    }
  })

  return icon
}
