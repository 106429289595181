import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        borderRadius: 12,
      },
      '& .MuiAutocomplete-paper': {
        borderRadius: 12,
      },
    },
    input: {
      fontSize: 13,
      borderRadius: 12,
    },
    option: {
      fontSize: 13,
    },
    label: {
      fontSize: 14,
    },
    popper: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bbb',
      borderRadius: '0.5rem',
      overflow: 'hidden',
      display: 'block',
      inset: '10px auto auto 0px',
    },
  })
)
