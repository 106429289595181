import { useUser, withPageAuthRequired } from '@auth0/nextjs-auth0/client'
import Hotjar from '@hotjar/browser'
import * as Sentry from '@sentry/nextjs'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getUserDetails } from 'api/login'
import { User } from 'api/login/login.types'
import axios from 'axios'
import { COOKIE } from 'constants/cookie'
import { ROUTES } from 'constants/routes'
import { useOrganizationDetails } from 'hooks/useOrganizationDetails'
import { getDataFromAxiosResponse } from 'lib/axios.getters'
import mixpanel from 'mixpanel-browser'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { resetAnanlyticsForUser } from 'utils/analytics'
import { fetchAccessTokenFromPageProps } from 'utils/authUtils'

import useCookie from '../useCookie'
import { logoutEvent } from './event'
import { AuthContextType } from './types'

export const checkAndUpdateAccessToken = async () => {
  try {
    const resp = await axios.get('/api/auth/get-access-token/')
    if (resp.data && resp.data?.status === 'error' && resp.data?.message === 'unable to fetch access token') {
      logoutEvent.emit('logout')
      return
    }
    if (resp.data && resp.data.accessToken) {
      fetchAccessTokenFromPageProps({ accessToken: resp.data.accessToken })
      return resp.data.accessToken as string
    }
  } catch (error) {
    console.log(error)
  }
}

export const AuthContext = React.createContext<AuthContextType>({} as AuthContextType)

const AuthProviderHelper = ({ children }: IAuthProps): JSX.Element => {
  const { user: auth0User, isLoading: auth0UserLoading } = useUser()
  const { value: authToken, removeItem: removeAuthCookie } = useCookie(COOKIE.authToken)
  const [user, setUser] = useState<User>()
  const [userAuthToken, setUserAuthToken] = useState(authToken)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const { push, pathname, query } = useRouter()
  useOrganizationDetails(!!query?.orgId && !!query?.year, parseInt(query?.year?.toString() || ''))

  const queryClient = useQueryClient()

  const onLogout = useCallback(() => {
    setIsLoading(true)
    setUserAuthToken('')

    // clear cookies
    removeAuthCookie()

    sessionStorage.clear()
    localStorage.clear()

    setUser(undefined)
    // redirect to login page
    push('/api/auth/logout', undefined).then(() => {
      // clear user
      queryClient.clear()
      setIsLoggedIn(false)
      setIsLoading(false)
      resetAnanlyticsForUser()
    })
  }, [push, queryClient, removeAuthCookie])

  useEffect(() => {
    auth0User && !auth0UserLoading && checkAndUpdateAccessToken()
  }, [auth0User, auth0UserLoading])

  const userResponse = useQuery({
    queryKey: ['getUserDetails', auth0User?.sub],
    queryFn: getUserDetails,
    enabled: !!auth0User && !auth0UserLoading,
    onSuccess: data => {
      const response = getDataFromAxiosResponse(data)
      Sentry.setUser({
        email: response.email,
        username: response.username,
        id: String(response.id),
        segment: String(response.organization),
      })
      mixpanel.identify(response.email)
      Hotjar.identify(response.email, { sub: auth0User?.sub || '' })
      setUser(response)
      setIsLoggedIn(true)
      setIsLoading(false)

      if (pathname === ROUTES.USER_LOGIN) {
        push(ROUTES.HOME)
      }
    },
    onSettled: () => {
      setIsLoading(false)
    },
    onError: () => {
      setIsLoading(false)
      setUser(undefined)
      setIsLoggedIn(false)
    },
  })

  const value: AuthContextType = useMemo(
    () => ({
      user,
      setUser,
      isLoggedIn: isLoggedIn, // will change to user object when data is received from api
      accessToken: userAuthToken,
      setUserAuthToken,
      onLogout,
      isFetching: isLoading,
      refetchUser: userResponse.refetch,
    }),
    [user, isLoggedIn, userAuthToken, onLogout, isLoading, userResponse.refetch]
  )

  useEffect(() => {
    logoutEvent.on('logout', onLogout)
    return () => {
      logoutEvent.removeListener('logout', onLogout)
    }
  }, [onLogout])

  // Auto check logic for updating latest access token and ensuring /get-access-token function remains hot
  useEffect(() => {
    const intervalId = setInterval(checkAndUpdateAccessToken, 5 * 60 * 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const AuthProvider = withPageAuthRequired(AuthProviderHelper)

export const useAuth = (): AuthContextType => React.useContext(AuthContext)

interface IAuthProps {
  children: React.ReactNode
}
