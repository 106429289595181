import cx from 'classnames'
import { ROUTES } from 'constants/routes'
import Router, { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { appActions } from 'store/reducer/app'

import SidebarHeader from './components/sidebarHeader'
import { findActiveMenuItem } from './helpers/sidebar.helpers'
import SideBarMenuRenderer from './organisms/sideBarMenuRenderer'
// import UserInfo from './organisms/userInfo'

const Sidebar = ({ activeLink, setActiveLink }: SidebarProps): JSX.Element => {
  const [isExtendedFieldsVisible, setIsExtendedFieldsVisible] = useState(false)
  const { isSidebarOpen } = useSelector((state: RootState) => state.app)
  const dispatch = useDispatch()

  const { pathname } = useRouter()

  useEffect(() => {
    // because in server side window is not defined
    const sidebarOpen = window.localStorage.getItem('sidebarOpen')
    if (sidebarOpen) {
      dispatch(appActions.updateSidebar(sidebarOpen === 'true'))
    }
  }, [dispatch])

  const handleSideBarToggle = useCallback(() => {
    dispatch(appActions.updateSidebar(!isSidebarOpen))
    window.localStorage.setItem('sidebarOpen', String(!isSidebarOpen))
  }, [dispatch, isSidebarOpen])

  const onActiveStateHandler = useCallback(
    (path: string) => () => {
      if (path !== ROUTES.DOCUMENT_MANAGEMENT) {
        setActiveLink(path)
        Router.push(path, undefined, { shallow: true })
      } else if (path === ROUTES.DOCUMENT_MANAGEMENT) {
        setActiveLink(ROUTES.DOCUMENT_STATUS)
        Router.push(ROUTES.DOCUMENT_STATUS, undefined, { shallow: true })
      }
    },
    [setActiveLink]
  )

  useEffect(() => {
    if (activeLink?.includes(ROUTES.DOCUMENT_MANAGEMENT)) {
      setIsExtendedFieldsVisible(true)
    }
  }, [activeLink])

  useEffect(() => {
    const activeMenuItem = findActiveMenuItem(pathname)
    if (activeMenuItem) {
      setActiveLink(pathname)
    }
  }, [setActiveLink, pathname])

  return (
    <div className="flex">
      <div
        className={cx(
          'bg-blue900 min-h-screen max-h-screen relative duration-150 flex flex-col items-center overflow-hidden overflow-y-scroll no-scrollbar',
          {
            'w-[13.75rem]': isSidebarOpen,
            'w-[4.5rem]': !isSidebarOpen,
          }
        )}>
        <SidebarHeader handleSideBarToggle={handleSideBarToggle} open={isSidebarOpen} />
        <SideBarMenuRenderer
          isExtendedFieldsVisible={isExtendedFieldsVisible}
          setIsExtendedFieldsVisible={setIsExtendedFieldsVisible}
          onActiveStateHandler={onActiveStateHandler}
          open={isSidebarOpen}
          activeLink={activeLink}
        />
        {/* <UserInfo isOpen={isSidebarOpen} setActiveLink={setActiveLink} /> */}
      </div>
    </div>
  )
}

interface SidebarProps {
  activeLink?: string
  setActiveLink: React.Dispatch<React.SetStateAction<string | undefined>>
}

export default Sidebar
