import {
  AutoGraph,
  DashboardOutlined,
  FileCopy,
  FolderCopy,
  HelpCenter,
  Language,
  MapsHomeWork,
  Repeat,
  // Summarize,
} from '@mui/icons-material'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { ROUTES } from 'constants/routes'

export const menus: IMenu[] = [
  {
    type: 'Entities',
    fields: [
      { title: 'Dashboard', src: DashboardOutlined, path: ROUTES.DASHBOARD },
      { title: 'Organization', src: Language, path: ROUTES.ORGANIZATION },
      { title: 'Legal Entities', src: MapsHomeWork, path: ROUTES.LEGAL_ENTITY_MANAGEMENT },
      { title: 'Transactions', src: Repeat, path: ROUTES.TRANSACTION_MANAGEMENT },
      {
        title: 'Documents',
        src: FolderCopy,
        path: ROUTES.DOCUMENT_ARCHIVAL,
      },
      { title: 'Benchmarks', src: FileCopy, path: ROUTES.BENCHMARK_MANAGEMENT },
      // { title: 'Templates', src: Summarize, path: ROUTES.TEMPLATE_MANAGEMENT },
      { title: 'Activity Logs', src: AutoGraph, path: ROUTES.ACTIVITY_LOG },
      { title: 'Help Center', src: HelpCenter, path: ROUTES.HELP_CENTER },
    ],
  },
  // {
  //   type: 'Management',
  //   fields: [
  //     { title: 'Setting & Support', src: Settings, path: ROUTES.SETTINGS },
  //     { title: 'User Management', src: People, path: ROUTES.USER_ACCESS_MANAGEMENT },
  //   ],
  // },
]

export interface IMenu {
  type: string
  fields: ISidearField[]
}

export interface ISidearField {
  title: string
  src:
    | (OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
        muiName: string
      })
    | string
  path: string
  subfields?: ISidearField[]
}
