import cx from 'classnames'
import Loading from 'components/loading'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

import AppLayoutHeader from '../../components/appLayoutHeader/AppLayoutHeader'
import { getRightComponent } from './appLayout.helper'
import { AppLayoutSectionProps } from './types/appLayout.types'

const AppLayoutSection = ({ children, activeLink, breadcrumbs }: AppLayoutSectionProps) => {
  const { isSidebarOpen } = useSelector((state: RootState) => state.app)
  const [pageChanging, setPageChanging] = useState(false)
  const [prevRoutePathname, setPrevRoutePathname] = useState<string | null>(null)

  const Router = useRouter()
  const dispatch = useDispatch()

  const rightComponent = useMemo(
    () => getRightComponent({ activeLink, Router, dispatch }),
    [activeLink, Router, dispatch]
  )

  const isHomePage = activeLink == ROUTES.HOME

  useEffect(() => {
    const startPageChange = (routePath: string) => {
      const prevRoute = prevRoutePathname
      let currentRoute = prevRoutePathname
      if (routePath) {
        const url = new URL(routePath, 'http://dummy-website.xyz')
        const currentPathname = url.pathname
        currentRoute = currentPathname
        setPrevRoutePathname(currentRoute)
      }
      if (prevRoute !== currentRoute) {
        setPageChanging(true)
      }
    }
    const endPageChange = () => {
      setPageChanging(false)
    }

    Router.events.on('routeChangeStart', startPageChange)
    Router.events.on('routeChangeComplete', endPageChange)
    Router.events.on('routeChangeError', endPageChange)

    return () => {
      Router.events.off('routeChangeStart', startPageChange)
      Router.events.off('routeChangeComplete', endPageChange)
      Router.events.off('routeChangeError', endPageChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Router])

  return (
    <div
      className={cx(
        'bg-white h-[calc(100vh-4rem)] relative pb-16 pr-6 shadow-appLayoutShadow pl-6 pt-6 overflow-y-scroll transition-all duration-300 ease-in-out',
        {
          'pr-6': isSidebarOpen,
          'p-[calc(100%-18rem)]': !isSidebarOpen,
          'rounded-2xl ': !isHomePage,
        }
      )}>
      <>
        <AppLayoutHeader breadcrumbs={breadcrumbs} headerRightComponent={rightComponent} activeLink={activeLink} />
        {children}
      </>
      {pageChanging && (
        <div className="flex absolute inset-0 flex-col h-full items-center bg-white z-50 justify-center">
          <Loading className="flex flex-col items-center justify-center" />
          <span className="w-[27.25vw] text-body text-center mt-4">
            Switching the module and fetching required resources ...
          </span>
        </div>
      )}
    </div>
  )
}

export default AppLayoutSection
