import { menus } from '../config/sidebar.config'
import { IGetIconClasses } from '../types/sidebar.types'

export function getIconClasses({ activeLink, fieldTitle, excludedTitles, isOpen }: IGetIconClasses) {
  return {
    'fill-blue800':
      activeLink?.includes(fieldTitle) &&
      !excludedTitles.includes(fieldTitle) &&
      !(activeLink.includes('document') && isOpen),
    'fill-blue100': activeLink !== fieldTitle && !excludedTitles.includes(fieldTitle),
    'stroke-blue800': activeLink?.includes(fieldTitle) && excludedTitles.includes(fieldTitle),
    'stroke-blue100': activeLink !== fieldTitle && excludedTitles.includes(fieldTitle),
  }
}

export const findActiveMenuItem = (currentPath: string) => {
  for (const menu of menus) {
    const menuItem = menu.fields.find(field => {
      return currentPath.startsWith(field.path)
    })
    if (menuItem) {
      if (menuItem?.subfields?.length) {
        const subMenuItem = menuItem.subfields.find(field => {
          return currentPath.startsWith(field.path)
        })
        if (subMenuItem) {
          return subMenuItem
        }
      }
      return menuItem
    }
  }
  return null
}
