import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs } from '@mui/material'
import { PORTAL_IDS } from 'components/Portal/constants'
import Typography, { Variant } from 'components/typography'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ROUTES_TITLES } from 'organisms/layout/constants/layout.constants'
import { findActiveMenuItem } from 'organisms/sidebar/helpers/sidebar.helpers'
import { useMemo } from 'react'

import { getActiveIconType } from './appLayout.helper'

const AppLayoutHeader = ({ activeLink, headerRightComponent, breadcrumbs }: AppLayoutHeaderProps) => {
  const ActiveIcon = useMemo(() => getActiveIconType(activeLink || ''), [activeLink])

  const { query } = useRouter()

  const title = findActiveMenuItem(activeLink || '')

  const name = query?.name || query?.txnName || query?.transactionName

  const renderTitle = useMemo(() => {
    return ROUTES_TITLES[activeLink || ''] || title?.title
  }, [activeLink, title?.title])

  const pageTitle = useMemo(
    () => [name, renderTitle, 'Integral'].filter(value => !!value).join(' - '),
    [name, renderTitle]
  )

  return (
    <div className="flex flex-col mb-6">
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-3">
          <div className="bg-blue900 h-[2.625rem] w-[2.625rem] flex items-center px-[0.625rem] rounded-xl">
            <ActiveIcon className="text-white" />
          </div>
          <Typography variant={Variant.Title2} type="bold" className="text-blue900">
            {renderTitle}
          </Typography>
        </div>
        {headerRightComponent}
        <div className={headerRightComponent ? 'hidden' : ''} id={PORTAL_IDS.APP_HEADER_RIGHT_COMPONENT_ID}></div>
      </div>
      <div className="flex items-center justify-between">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        <div id={PORTAL_IDS.BREADCRUMBS_RIGHT_COMPONENT_ID} />
        {/* <BreadCrumbsRightComponent activeLink={activeLink} /> */}
      </div>
    </div>
  )
}

interface AppLayoutHeaderProps {
  activeLink?: string
  headerRightComponent?: React.ReactNode
  breadcrumbs: (JSX.Element | undefined)[]
}

export default AppLayoutHeader
