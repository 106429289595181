import { Home, People, Settings } from '@mui/icons-material'
import { ROUTES } from 'constants/routes'

export const navlinks = [
  { title: 'Home', src: Home, path: ROUTES.HOME },
  { title: 'Setting & Support', src: Settings, path: ROUTES.SETTINGS },
  { title: 'User Management', src: People, path: ROUTES.USER_ACCESS_MANAGEMENT },
]

export const classConfig = {
  base: 'flex cursor-pointer h-12 w-full whitespace-nowrap w-full font-[600] px-3 items-center justify-center gap-2 rounded',
  active: 'bg-neutral100 text-blue800',
  inactive: 'hover:bg-blue800 text-white',
}
