import { getIcons } from 'assets'
import cx from 'classnames'
import React, { useMemo } from 'react'

import { baseClass } from './button.config'
import {
  getButtonSizeClass,
  getIconClassBasedOnVariant,
  getRestButtonConfig,
  VariantMappingToClass,
} from './button.helper'
import { ButtonProps, ButtonSize, ButtonVariant, IconPlacement } from './button.types'

const Button = ({
  children,
  isFullWidth = false,
  icon,
  iconPlacement = IconPlacement.Left,
  variant = ButtonVariant.Primary,
  disabled = false,
  buttonType = 'button',
  onClick,
  size = ButtonSize.Small,
  className,
  iconCLass,
  ref,
  isDefaultSize = true,
  iconPathClassName,
}: ButtonProps): JSX.Element => {
  const variantClass = VariantMappingToClass[variant]
  const paddingClass = useMemo(() => getButtonSizeClass(size), [size])

  const restButtonConfig = useMemo(
    () =>
      getRestButtonConfig({
        isFullWidth,
        icon,
        iconPlacement,
      }),
    [isFullWidth, iconPlacement, icon]
  )

  const iconClassName = useMemo(
    () =>
      isDefaultSize
        ? getIconClassBasedOnVariant(variant, size, disabled)
        : {
            className: iconCLass,
            pathClassName: iconPathClassName,
          },
    [isDefaultSize, variant, size, disabled, iconCLass, iconPathClassName]
  )

  return (
    <button
      ref={ref}
      onClick={onClick}
      disabled={disabled}
      type={buttonType}
      className={cx(
        baseClass,
        variantClass,
        restButtonConfig,
        paddingClass,
        { 'px-[0rem]': variant === ButtonVariant.Tertiary },
        className
      )}>
      {children}
      {icon && getIcons(icon, iconClassName)}
    </button>
  )
}

Button.Variant = ButtonVariant

export default Button
