import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import { ROUTES } from 'constants/routes'
import Router from 'next/router'
import { appActions } from 'store/reducer/app'

import { RightSectionComponentProps } from './types/appLayout.types'

export const getRightComponent = ({ activeLink, dispatch }: RightSectionComponentProps) => {
  // TODO: Still need to work on this component
  const handleAddUserModal = () => {
    dispatch(appActions.updateAddUserModalState({ open: true }))
  }
  const handleUsersImportModal = () => {
    dispatch(appActions.updateImportUserModalState(true))
  }
  const handleUsersExportModal = () => {
    dispatch(appActions.updateExportUserModalState(true))
  }
  const handleUpdateBenchmarkModal = () => {
    dispatch(appActions.updateBenchmarkModalState(true))
  }

  if (activeLink === ROUTES.BENCHMARK_MANAGEMENT) {
    {
      return (
        <Button icon={IconsType.plus} onClick={handleUpdateBenchmarkModal}>
          Add New
        </Button>
      )
    }
  }
  if (activeLink === ROUTES.TEMPLATE_MANAGEMENT) {
    {
      return <Button icon={IconsType.plus}>Create Template</Button>
    }
  }

  if (activeLink === ROUTES.USER_ACCESS_MANAGEMENT) {
    {
      return (
        <div className="flex gap-3">
          <Button icon={IconsType.plus} onClick={handleAddUserModal}>
            Add New
          </Button>
          <Button
            isDefaultSize={false}
            icon={IconsType.upload}
            variant={ButtonVariant.Secondary}
            onClick={handleUsersImportModal}
            className="text-violet-600 border-violet-600 !bg-violet-100 hover:!bg-violet-200"
            iconPathClassName="stroke-violet-600">
            Import
          </Button>
          <Button
            isDefaultSize={false}
            icon={IconsType.download}
            variant={ButtonVariant.Secondary}
            onClick={handleUsersExportModal}
            className="text-teal-600 border-teal-600 !bg-teal-100 hover:!bg-teal-200"
            iconPathClassName="stroke-teal-600">
            Export (Excel)
          </Button>
        </div>
      )
    }
  }

  return null
}

export const getFormattedPath = (path: string) => {
  if (path === '[id]') {
    if (Router.pathname === `${ROUTES.TRANSACTION_MANAGEMENT}/[id]`) {
      return Router.query.txnName
    }
  }

  return path.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
}
