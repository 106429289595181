export const BaseUrl = process.env.NEXT_PUBLIC_API_URL

export const APIS = {
  BENCHMARKS: {
    LIST: '/api/v1/org/{organization}/transaction/benchmarking/',
    ITEM: '/api/v1/org/{organization}/transaction/benchmarking/{id}/', // create, edit, post
  },
  TRANSACTIONS: {
    GET_DEFAULT_CONCLUSION: '/api/v1/org/{organization}/transaction/transactions/get_conclusion/',
    GET_AI_CONCLUSION: '/api/v1/org/{organization}/recommendations/generate_transaction_conclusion',
  },
}
