import colors from 'tailwindcss/colors'

const AppColors = {
  ...colors,
  neutral50: colors.neutral['50'],
  neutral100: colors.slate['100'],
  neutral200: colors.slate['200'],
  neutral300: colors.slate['300'],
  neutral400: colors.slate['400'],
  neutral500: colors.slate['500'],
  neutral600: colors.slate['600'],
  neutral800: colors.slate['800'],
  red50: colors.red['50'],
  red500: colors.red['500'],
  red600: colors.red['600'],
  grey100: '#D9D9D9',
  grey200: '#EAECF0',
  grey300: '#D1D5DB',
  grey400: '#9CA3AF',
  grey500: colors.gray['500'],
  green50: colors.emerald['50'],
  green500: colors.emerald['500'],
  blue50: colors.blue['50'],
  blue100: colors.blue['100'],
  blue600: colors.blue['600'],
  blue700: colors.blue['700'],
  blue800: colors.blue['800'],
  blue900: colors.blue['900'],
  teal600: colors.teal['600'],
  orange600: colors.orange['600'],
  trinidad50: '#FFF3ED',
  lightBlack: '#333333',
  lightGreen: '#F0FDF5',
}

export default AppColors
