import { Variant } from 'components/typography'
import Typography from 'components/typography/Typography'
import { ROUTES } from 'constants/routes'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { BREADCRUMBS_CONFIG } from 'organisms/layout/constants/layout.constants'
import { addOrgIdToRoute } from 'utils/routeUtils'
import { v4 } from 'uuid'

const BREADCRUMBS_LINK = (route: string, text: string, orgID?: string) => {
  return (
    <Link className="text-neutral-600 no-underline" href={addOrgIdToRoute(route, orgID)} key={v4()}>
      <Typography variant={Variant.Body} type="semibold">
        {text}
      </Typography>
    </Link>
  )
}

const BREADCRUMBS_TEXT = (text: string, isInactive?: boolean) => (
  <Typography
    key={v4()}
    variant={Variant.Body}
    className={isInactive ? 'text-blue900' : ''}
    type={isInactive ? 'bold' : 'semibold'}>
    {text}
  </Typography>
)

const useBreadcrumbs = () => {
  const { pathname, query } = useRouter()
  const isEditItemMode = query.isEdit === 'true'

  const breadcrumbsConfig = BREADCRUMBS_CONFIG()
  const matchedRoute = Object.keys(breadcrumbsConfig).find(routePath => pathname === routePath)
  if (matchedRoute) {
    const routeData = breadcrumbsConfig[matchedRoute]

    if (routeData.type === 'detail') {
      return [
        BREADCRUMBS_LINK(routeData.parent.route, routeData.parent.name, query?.orgId as string | undefined),
        BREADCRUMBS_TEXT(decodeURIComponent(query[routeData.nameIdentifier]?.toString() || ''), true),
      ]
    }

    if (routeData.type === 'create' && !isEditItemMode) {
      return [
        BREADCRUMBS_LINK(routeData.parent.route, routeData.parent.name, query?.orgId as string | undefined),
        BREADCRUMBS_TEXT(`Create ${routeData.name}`, true),
      ]
    }

    if (routeData.type === 'create' && isEditItemMode && matchedRoute !== ROUTES.CREATE_TRANSACTION) {
      return [
        BREADCRUMBS_LINK(routeData.parent.route, routeData.parent.name, query?.orgId as string | undefined),
        BREADCRUMBS_LINK(
          `${routeData.parent.route}/${query[routeData.itemIdentifier || '']}/?name=${query[routeData.nameIdentifier]}`,
          query[routeData.nameIdentifier]?.toString() || '',
          query?.orgId as string | undefined
        ),
        BREADCRUMBS_TEXT(`Edit ${routeData.name}` || '', true),
      ]
    }

    if (routeData.type === 'create' && isEditItemMode && matchedRoute === ROUTES.CREATE_TRANSACTION) {
      return [
        BREADCRUMBS_LINK(routeData.parent.route, routeData.parent.name, query?.orgId as string | undefined),
        BREADCRUMBS_LINK(
          `${routeData.parent.route}/${query[routeData.itemIdentifier || '']}/?txnName=${encodeURIComponent(
            query[routeData.nameIdentifier] as string
          )}&year=${query.year}`,
          query[routeData.nameIdentifier]?.toString() || '',
          query?.orgId as string | undefined
        ),
        BREADCRUMBS_TEXT(`Edit ${routeData.name}`, true),
      ]
    }
  }

  return []
}

export default useBreadcrumbs
