import cx from 'classnames'
import useSubscriptions from 'hooks/useSubscriptions'
import React from 'react'

function SubscriptionPill({ className }: { className?: string }) {
  const { activeSubscription, isLoading } = useSubscriptions()
  const type = activeSubscription?.type || 'Trial'
  return !isLoading ? (
    <span
      className={cx(
        `px-2 py-1  rounded-full w-fit text-[0.7rem] font-semibold`,
        {
          'bg-gradient-to-tr from-yellow-400 via-yellow-200 to-yellow-600 text-yellow-800': type === 'Premium',
          'bg-gray-300 text-gray-600': type !== 'Premium',
        },
        className
      )}>
      <span className="drop-shadow-md">{type}</span>
    </span>
  ) : (
    <></>
  )
}

export default SubscriptionPill
