import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Icon, ListItemIcon } from '@mui/material'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import { ROUTES } from 'constants/routes'
import { ISidearField } from 'organisms/sidebar/config/sidebar.config'

import { config, titleConfig } from './entitiesButton.classConfig'

const EntitiesButton = ({
  activeLink,
  onActiveStateHandler,
  field,
  open,
  isSubFieldIncluded,
  isTextCenter,
  onSubFieldClick,
  isExtendedFieldsVisible,
  className,
}: EntitiesButtonProps) => {
  const isActive = !open ? activeLink?.includes(field.path) : activeLink?.includes(field.path) && isSubFieldIncluded

  return (
    <div
      onClick={onActiveStateHandler(field.path)}
      className={cx(
        config.base,
        'flex-col relative w-full',
        {
          [config.active]: isActive,
          [config.inactive]: !isActive && !isSubFieldIncluded,
          [config.subField]:
            field.path.includes(ROUTES.DOCUMENT_MANAGEMENT) &&
            activeLink?.includes(ROUTES.DOCUMENT_MANAGEMENT) &&
            !field.path.includes(ROUTES.DOCUMENT_STATUS) &&
            !field.path.includes(ROUTES.DOCUMENT_ARCHIVAL),
        },
        className
      )}>
      {isActive && (
        <div className="flex items-end justify-end absolute right-0 top-[-9px]">
          {getIcons(IconsType.sideBarEclipse)}
        </div>
      )}
      <div className="flex w-full items-center justify-between h-full">
        <div className="flex items-center gap-2 h-full w-full">
          {isActive && (
            <div className="flex items-end justify-end absolute right-0 bottom-[-9px] rotate-[270deg]">
              {getIcons(IconsType.sideBarEclipse)}
            </div>
          )}
          {field.src && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                color: !isActive ? 'white' : '#1E40AF',
              }}>
              {<field.src />}
            </ListItemIcon>
          )}

          <span
            className={cx(
              `${!open && titleConfig.hidden}`,
              titleConfig.base,
              {
                [titleConfig.active]: isActive,
                [titleConfig.inactive]: !isActive,
                'text-center w-full': isTextCenter,
              },
              'text-sm'
            )}>
            {field.title}
          </span>
        </div>
        {open && field.subfields && (
          <Icon onClick={onSubFieldClick}>
            <KeyboardArrowDownIcon
              className={cx({
                'transform rotate-180 duration-300 ': isExtendedFieldsVisible,
              })}
              sx={{
                fill: 'white',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </Icon>
        )}
      </div>
    </div>
  )
}

interface EntitiesButtonProps {
  activeLink?: string
  onActiveStateHandler: (path: string) => VoidFunction
  field: ISidearField
  open: boolean
  isSubFieldIncluded?: boolean
  isTextCenter?: boolean
  onSubFieldClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isExtendedFieldsVisible?: boolean
  className?: string
}

export default EntitiesButton
