import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useMemo } from 'react'
import { capitalizeFirstLetter } from 'utils/utils'

import { getColorOnCell } from './helpers'

const ModelNameCell = ({ moduleName, className, size = 'regular' }: ModelNameCellProps): JSX.Element => {
  const cellContentManagement = useMemo(() => getColorOnCell(moduleName), [moduleName])

  const getIconsPathClassName = () => {
    if (moduleName === 'Legal Entity') {
      return 'fill-[#0D9488]'
    }
    if (moduleName === 'Transaction') {
      return 'fill-[#F96416]'
    }
    if (moduleName === 'Document Management') {
      return 'fill-[#955CF6]'
    }
    if (moduleName === 'Organization') {
      return 'fill-[#1e40af]'
    }
    if (moduleName === 'User Management') {
      return 'fill-[#F4C430]'
    }
    return `stroke-[${cellContentManagement?.color}]`
  }

  return (
    <div
      style={{
        background: cellContentManagement?.bg,
      }}
      className={cx(
        'h-[1.5rem] w-fit px-4 py-1  flex  items-center justify-center rounded-[2.5rem] gap-[0.5rem]',
        className
      )}>
      {getIcons(cellContentManagement?.icon as IconsType, {
        pathClassName: getIconsPathClassName(),
        className: cx('w-[1.125rem] h-[1.125rem]', { 'w-4 h-4': size == 'small' }),
      })}
      <Typography
        style={{
          color: cellContentManagement?.color,
        }}
        className={cx({ 'text-[0.75rem]': size == 'small' })}
        variant={Variant.Callout}
        type="semibold">
        {capitalizeFirstLetter(moduleName)}
      </Typography>
    </div>
  )
}

interface ModelNameCellProps {
  moduleName: string
  className?: string
  size?: 'regular' | 'small'
}

export default ModelNameCell
