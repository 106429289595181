import { useQuery } from '@tanstack/react-query'
import { getSubscriptionsList } from 'api/subscriptions'
import { SubscriptionDetail } from 'api/subscriptions/types'

function useSubscriptions() {
  const res = useQuery(['getSubscriptions'], {
    queryFn: () => getSubscriptionsList(),
  })
  const { data: subscriptionList, isLoading, isError } = res

  function getActiveSubscription(subscriptions: SubscriptionDetail[]): SubscriptionDetail | undefined {
    if (!subscriptions?.length) return
    const activeSubscriptions = subscriptions.filter(sub => sub.status === 'Active')

    if (activeSubscriptions.length === 0) {
      return
    }

    let subscriptionWithGreatestDate = activeSubscriptions[0]

    for (let i = 1; i < activeSubscriptions.length; i++) {
      const currentSubscription = activeSubscriptions[i]

      const greatestDate = subscriptionWithGreatestDate.end_date || subscriptionWithGreatestDate.start_date
      const currentDate = currentSubscription.end_date || currentSubscription.start_date

      if (greatestDate && currentDate) {
        if (new Date(currentDate) > new Date(greatestDate)) {
          subscriptionWithGreatestDate = currentSubscription
        }
      }
    }

    return subscriptionWithGreatestDate
  }

  const activeSubscription = subscriptionList && getActiveSubscription(subscriptionList)
  return {
    subscriptionList,
    activeSubscription,
    isLoading,
    isError,
  }
}

export default useSubscriptions
