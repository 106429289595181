import { ListItemIcon, Tooltip } from '@mui/material'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import UserInfo from 'organisms/sidebar/organisms/userInfo/UserInfo'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

import GlobalSearch from '../globalSearch/GlobalSearch'
import YearSelector from '../YearSelector/YearSelector'
import { classConfig, navlinks } from './config/navlinks.config'
import { organisationCellConfig } from './constants/organisationCell.constants'

function Navbar() {
  const router = useRouter()
  const { organisation } = useSelector((state: RootState) => state.app)

  const handleNavigation = useCallback(
    (path: string) => () => {
      router.push(path)
    },
    [router]
  )

  const isHomePage = router.pathname == ROUTES.HOME

  return (
    <div className="h-16 w flex gap-4 pe-4 items-center">
      {isHomePage && getIcons(IconsType.integralOpenLogo, { className: 'w-48 h-12' })}
      {!isHomePage && (
        <>
          <GlobalSearch />
          <span className="text-white text-[0.8rem] font-semibold ms-4">
            Fiscal Year: <YearSelector containerClassname="inline-block	ms-2" />
          </span>
        </>
      )}

      <div className="flex ms-auto items-center gap-4">
        {!isHomePage && (
          <Tooltip title={`Organisation: ${organisation?.name || ''}`} placement="bottom">
            <div className="tox h-12 w-fit px-4 py-1 flex items-center justify-center rounded-2xl gap-[0.5rem] cursor-default">
              {getIcons(organisationCellConfig?.icon, {
                pathClassName: organisationCellConfig.iconPathClass,
                className: 'w-4 h-4',
              })}
              <Typography
                style={{ color: organisationCellConfig?.color }}
                className="whitespace-nowrap font-poppins uppercase"
                variant={Variant.Body}
                type="semibold">
                {organisation?.name || ''}
              </Typography>
            </div>
          </Tooltip>
        )}
        {navlinks.map(navLinkItem => {
          const isActive = router.pathname.includes(navLinkItem.path)
          if (navLinkItem.title !== 'Home' && isHomePage) {
            return null
          }
          return (
            <Tooltip key={navLinkItem.path} title={navLinkItem.title} placement="bottom-end">
              <div
                onClick={handleNavigation(navLinkItem.path)}
                className={cx(classConfig.base, { [classConfig.active]: isActive, [classConfig.inactive]: !isActive })}>
                {navLinkItem.src && (
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      color: !isActive ? 'white' : '#1E40AF',
                    }}>
                    {<navLinkItem.src />}
                  </ListItemIcon>
                )}
                {/* {navLinkItem.title} */}
              </div>
            </Tooltip>
          )
        })}
      </div>
      <UserInfo isOpen={false} />
    </div>
  )
}

export default Navbar
