import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import SubscriptionPill from 'components/subscriptionPill/SubscriptionPill'

import { collapseContainerConfig } from './sidebarHeader.config'

const SidebarHeader = ({ open, handleSideBarToggle }: SidebarHeaderProps) => {
  const logoToRender = open ? IconsType.integralOpenLogo : IconsType.closeIntegralLogo

  return (
    <div
      className={cx('flex flex-col mt-2.5 w-full', {
        'items-center': !open,
      })}>
      <div className="px-3">
        {getIcons(logoToRender, {
          className: cx('relative top-[-3px]', {
            'w-[10.8125rem] h-[2.9906rem]': open,
            'w-[2.9906rem] h-[2.9906rem]': !open,
          }),
        })}
      </div>
      <SubscriptionPill className="self-center" />
      <div
        className={cx(
          `${collapseContainerConfig.base} ${open ? collapseContainerConfig.open : collapseContainerConfig.closed}`
        )}
        onClick={handleSideBarToggle}>
        {!open && (
          <KeyboardDoubleArrowRightIcon
            sx={{
              fill: 'white',
              cursor: 'pointer',
            }}
          />
        )}
        {open && (
          <KeyboardDoubleArrowLeftIcon
            sx={{
              fill: 'white',
              cursor: 'pointer',
            }}
          />
        )}
      </div>
    </div>
  )
}

interface SidebarHeaderProps {
  open: boolean
  handleSideBarToggle: () => void
}

export default SidebarHeader
