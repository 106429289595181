import { IconProps } from 'assets'
import React from 'react'

const CloseIntegralLogo = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="64"
      height="48"
      viewBox="0 0 64 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_59_242)">
        <path
          d="M1.40155 20.545C-0.464579 22.411 -0.46458 25.4365 1.40155 27.3025L20.5479 46.448C22.414 48.314 25.4396 48.314 27.3057 46.448L46.452 27.3025C48.3182 25.4365 48.3182 22.411 46.452 20.545L36.3811 10.4745L33.026 13.8295L41.5767 22.3798C42.4291 23.2322 42.4291 24.6143 41.5767 25.4667L25.6907 41.352C24.8382 42.2044 23.4561 42.2044 22.6036 41.352L6.7177 25.4667C5.86522 24.6143 5.86522 23.2322 6.7177 22.3798L22.6036 6.49459C23.4561 5.64215 24.8382 5.64215 25.6907 6.49459L26.6649 7.4687L30.02 4.11372L27.3057 1.39953C25.4396 -0.466511 22.414 -0.466512 20.5479 1.39953L1.40155 20.545Z"
          fill="url(#paint0_linear_59_242)"
        />
        <path
          d="M31.4359 12.2395L34.791 8.88451L31.6101 5.70373L28.255 9.05872L31.4359 12.2395Z"
          fill="url(#paint1_linear_59_242)"
        />
        <path
          d="M16.7666 20.546C14.9005 22.4121 14.9005 25.4375 16.7666 27.3036L26.8373 37.3738L30.1918 34.0194L21.6403 25.4683C20.7878 24.6158 20.7878 23.2337 21.6403 22.3813L37.5262 6.4961C38.3787 5.64366 39.7608 5.64366 40.6133 6.4961L56.4993 22.3813C57.3517 23.2337 57.3517 24.6158 56.4993 25.4683L40.6133 41.3535C39.7608 42.2059 38.3787 42.2059 37.5262 41.3535L36.5529 40.3802L33.1984 43.7346L35.9129 46.449C37.7791 48.3151 40.8047 48.3151 42.6708 46.449L61.8171 27.3036C63.6832 25.4375 63.6832 22.4121 61.8171 20.546L42.6708 1.40056C40.8047 -0.465481 37.7791 -0.465481 35.9129 1.40056L16.7666 20.546Z"
          fill="url(#paint2_linear_59_242)"
        />
        <path
          d="M31.6083 42.1446L34.9628 38.7902L31.7819 35.6094L28.4274 38.9638L31.6083 42.1446Z"
          fill="url(#paint3_linear_59_242)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_59_242"
          x="0.00195312"
          y="0"
          width="63.2147"
          height="49.8485"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_59_242" />
        </filter>
        <linearGradient
          id="paint0_linear_59_242"
          x1="39.2918"
          y1="0.000987678"
          x2="39.2918"
          y2="47.8485"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBE6FF" />
          <stop offset="1" stopColor="#00C3C3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_59_242"
          x1="39.2918"
          y1="0.000987678"
          x2="39.2918"
          y2="47.8485"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBE6FF" />
          <stop offset="1" stopColor="#00C3C3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_59_242"
          x1="39.2918"
          y1="0.000987678"
          x2="39.2918"
          y2="47.8485"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBE6FF" />
          <stop offset="1" stopColor="#00C3C3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_59_242"
          x1="39.2918"
          y1="0.000987678"
          x2="39.2918"
          y2="47.8485"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBE6FF" />
          <stop offset="1" stopColor="#00C3C3" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default CloseIntegralLogo
