import classNames from 'classnames'
import ActiveTagBar from 'organisms/sidebar/components/activeTagBar'
import EntitiesButton from 'organisms/sidebar/components/entitiesButton'
import EntitiesTitle from 'organisms/sidebar/components/entitiesTitle'
import { menus } from 'organisms/sidebar/config/sidebar.config'
import React, { useCallback } from 'react'

const SideBarMenuRenderer = ({
  activeLink,
  onActiveStateHandler,
  open,
  isExtendedFieldsVisible,
  setIsExtendedFieldsVisible,
}: SideBarMenuRendererProps) => {
  const onSubFieldClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      setIsExtendedFieldsVisible(!isExtendedFieldsVisible)
    },
    [isExtendedFieldsVisible, setIsExtendedFieldsVisible]
  )

  return (
    <ul className="flex flex-col gap-2 w-full">
      {menus.map((menu, index) => (
        <div key={index}>
          {menu.type !== 'Entities' && <EntitiesTitle menu={menu} open={open} />}
          <ul className="flex flex-col gap-4">
            {menu.fields.map((field, index) => {
              const isActiveBar = !field.subfields && activeLink?.includes(field.path)
              return (
                <div key={index} className="flex items-center">
                  <div className="flex flex-col items-center w-full">
                    <div className="flex items-center w-full gap-1">
                      <div
                        className={classNames('w-1', {
                          visible: isActiveBar,
                          invisible: !isActiveBar,
                        })}>
                        <ActiveTagBar />
                      </div>
                      <EntitiesButton
                        open={open}
                        activeLink={activeLink}
                        field={field}
                        onActiveStateHandler={onActiveStateHandler}
                        isSubFieldIncluded={activeLink?.includes(field.path) && !field.subfields}
                        onSubFieldClick={onSubFieldClick}
                        isExtendedFieldsVisible={isExtendedFieldsVisible}
                      />
                    </div>

                    {field.subfields &&
                      isExtendedFieldsVisible &&
                      open &&
                      field.subfields.map((subfield, index) => (
                        <div key={index} className="flex mt-2 items-center gap-1 w-full">
                          {activeLink?.includes(subfield.path) && <ActiveTagBar />}
                          <EntitiesButton
                            open={open}
                            activeLink={activeLink}
                            field={subfield}
                            onActiveStateHandler={onActiveStateHandler}
                            isSubFieldIncluded={!!field?.subfields?.length}
                            isExtendedFieldsVisible={isExtendedFieldsVisible}
                            className="pl-12"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )
            })}
          </ul>
        </div>
      ))}
    </ul>
  )
}

interface SideBarMenuRendererProps {
  activeLink?: string
  onActiveStateHandler: (path: string) => VoidFunction
  open: boolean
  setIsExtendedFieldsVisible: React.Dispatch<React.SetStateAction<boolean>>
  isExtendedFieldsVisible: boolean
}

export default SideBarMenuRenderer
