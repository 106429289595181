export const config = {
  base: 'flex cursor-pointer h-12 w-full rounded-sidebar px-3',
  active: 'bg-neutral100',
  subField: 'bg-blue800',
  inactive: 'hover:bg-blue800 ml-1',
}

export const titleConfig = {
  base: 'whitespace-nowrap w-full font-[600]',
  hidden: 'hidden',
  active: 'text-blue800',
  inactive: 'text-white',
}
